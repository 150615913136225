@tailwind base;
@tailwind components;
@tailwind utilities;

@import "swiper/css";
@import "swiper/css/pagination";
@import "swiper/css/navigation";

:root {
  --swiper-theme-color: #6b7280;
}